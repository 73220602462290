import ApiFactory from '../factories/apiFactory';
import qs from 'qs';

const detailUrl = ""
const listUrl = `${process.env.VUE_APP_BASE_URL}/anagrafiche/punti-vendita-concorrenza/?_l=${process.env.VUE_APP_MAX_COUNT}`;
const requestConfiguration = {
    headers: {
        "content-type": "application/json",
        'client-key': process.env.VUE_APP_CLIENT_KEY,
    },
    params: {},
    paramsSerializer: (params: any) => qs.stringify(params, { encode: false }),
}

export interface Negozio {
    _id: string,
    __STATE__: string,
    codiceAmbiente: string,
    codiceSocieta: string,
    codicePuntoVenditaConcorrenza: string,
    catena: string,
    ragioneSociale: string,
    attivo: string,
    indirizzi: [
        {
            codiceTipoIndirizzo: string,
            descrizioneTipoIndirizzo: string,
            via: string,
            CAP: string,
            località: string,
        }
    ],
}

const negozi = {
    async detail(id: string | string[]): Promise<Negozio> {
        return await new Promise((resolve, reject) =>
            ApiFactory.get(detailUrl + '/' + id, requestConfiguration)
                .then(
                    (success) => resolve(success.data),
                    (error) => reject(error))
        )
    },

    async list() {
        let result = new Array<Negozio>;
        requestConfiguration.params = {
            //'_s': 'catena',
            //'_q': '{attivo:{$in:[S]}}' da tivedere problema virgolette
        }
        result = await (await ApiFactory.get(listUrl, requestConfiguration)).data
        return result
    },
}

export default negozi;