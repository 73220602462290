import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import jwt_decode from 'jwt-decode';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/shops',
    component: () => import('../views/Shops/ShopsList.vue')// lista negozi per iniziare la nuova rilevazione
  },
  {
    path: '/surveys',
    component: () => import('../views/Surveys/SurveysList.vue')// lista rilevazioni
  },
  {
    path: '/surveys/products',
    component: () => import('../views/Products/ProductList.vue')// lista prezzi si una rilevazione esistente
  },
  {
    path: '/surveys/new',
    component: () => import('../views/Surveys/NewScan.vue')// Nuova rilevazione
  },
  {
    path: '/surveys/edit/scan',
    component: () => import('../views/Surveys/NewScan.vue')// scannerizzazione alticolo
  },
  {
    path: '/surveys/:id_rilevazione/:product_ean/edit',
    component: () => import('../views/Products/EditProduct.vue') // modifica prezzo in una rilevazione esistente
  },
  {
    path: '/surveys/new/product',
    component: () => import('../views/Products/NewProduct.vue')// aggiunta nuovo prezzo in una nuova rilevazione
  },
  {
    path: '/surveys/edit/product',
    component: () => import('../views/Products/NewProductScan.vue')// aggiunta nuovo prezzo in una rilevazione esistente
  },
  {
    name: "login",
    path: '/login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to) => {
  if (to.query.id_token) {
    const token = {
      id_token: to.query.id_token,
      refresh_token: to.query.refresh_token
    }
    // save token
    store.commit('auth/setToken', token);
    store.commit('auth/setIsLogged', true);
    // save codiceUtente
    let decodedToken: string[] = [];
    const idToken: string = token.id_token.toString();
    decodedToken = jwt_decode(idToken);
    store.commit('auth/setCodiceUtente', decodedToken['preferred_username'].split('@')[0]);
    router.push("/")
  }
  if (!store.getters['auth/isLogged'] && to.path !== '/login') {
    await router.push("/login")
  }
})
router.beforeEach(async (to, from) => {

  if (to.path == '/') {
    store.commit("surveys/selectProduct", {});
    store.commit("surveys/selectRilevazione", {});
    store.commit("surveys/selectShop", {});
  }
  if (to.path == '/shops') {
    store.commit("surveys/selectShop", {});
  }
  if (to.path == '/surveys') {
    store.commit("surveys/selectRilevazione", {});
  }
  if (to.path == '/surveys/products') {
    store.commit("surveys/selectProduct", {});
  }

})

export default router
