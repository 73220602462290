import "../../services/negozi"
import negozi from "../../services/negozi";
import surveys from "@/services/surveys";
import store from "..";

const state = () => ({
    negozi: [],//negozi
    rilevazioni: [],
    rilevazione: null,
    shop: null,
    idRilevazione: null,
    prodotto: {},
    notFound: false,
    permissionsAccepted: false,
    prodotti: [],
    codicePuntoVenditaConcorrenza: null
})


// getters
const getters = {
    notFound(state: any) {
        return state.notFound
    },
    getRilevazione: (state: any) => {
        return state.rilevazione
    },
    getIdRilevazione: (state: any) => {
        return state.idRilevazione
    },
    getNegozio: (state: any) => {
        return state.shop
    },
    getCodiceNegozio: (state: any) => {
        return state.codicePuntoVenditaConcorrenza
    },
    getProduct: (state: any) => {
        return state.prodotto
    },
    nomeNegozioById: (state: { negozi: Array<any> }) => (id: any) => {
        const shop = state.negozi.findIndex((negozio: { codicePuntoVenditaConcorrenza: any; }) => negozio.codicePuntoVenditaConcorrenza == id);
        if(shop != -1){
            return state.negozi[shop].ragioneSociale;
        }else{
            return ""
        }
    },
    getNegozioById: (state: { negozi: Array<any> }) => async (id: any) => {
        if (JSON.stringify(state.negozi) == JSON.stringify([])) {
            await store.dispatch('surveys/getAllNegozi')
        }
        const shop = state.negozi.findIndex((negozio: { codicePuntoVenditaConcorrenza: any; }) => negozio.codicePuntoVenditaConcorrenza == id);
        return state.negozi[shop];
    },
    rilevazioni: (state: { rilevazioni: Array<any> }) => {
        return state.rilevazioni;
    },
    rilevazioneById: (state: { rilevazioni: Array<any> }) => async (id: any) => {
        let rilevazione = state.rilevazioni.find(rilevazione => rilevazione.idRilevazione == id);
        if (typeof rilevazione == 'undefined') {
            rilevazione = await surveys.detail(id)//
        }
        return rilevazione
    },
    getDescrizioneByEan: (state: any) => (ean: any) => {
        const id = state.prodotti.findIndex((prodotto: { ean: any; }) => prodotto.ean == ean);
        if (id == -1) {
            return null
        }
        return state.prodotti[id].descrizione;
    },
    negozi: (state: { negozi: Array<any> }) => {
        return state.negozi;
    },
    activeSurveys: (state: { rilevazioni: Array<any> }) => {
        return state.rilevazioni.filter(rilevazione => rilevazione.chiusa == false);
    },
    prodotti: (state: { prodotti: Array<any> }) => {
        return state.prodotti;
    },
    getPermissions: (state: any) => {
        return state.permissionsAccepted;
    }
}

// actions
const actions = {
    async getAllNegozi({ commit }: any) {
        const data = await negozi.list()
        commit('setNegozi', data)
    },
}

const mutations = {
    notFound(state: any, val: any) {
        state.notFound = val
    },
    selectRilevazione(state: any, rilevazione: any) {
        state.rilevazione = rilevazione
    },
    selectProduct(state: any, prodotto: any) {
        state.prodotto = prodotto
    },
    selectIdRilevazione(state: any, id: any) {
        state.idRilevazione = id
    },
    selectShop(state: any, puntoVenditaConcorrenza: any) {
        state.shop = puntoVenditaConcorrenza
    },
    setCodiceNegozio: (state: any, codicePuntoVenditaConcorrenza: any) => {
        state.codicePuntoVenditaConcorrenza = codicePuntoVenditaConcorrenza;
    },
    addProduct(state: any, addP: { id: any, prodotto: any }) {//aggiungi un prodotta ad una rilevazione aperta
        state.rilevazioni.filter((rilevazione: { id: any }) => rilevazione.id == addP.id)[0].prodotti.push(addP.prodotto);
    },
    setSurveys(state: any, surveys: Array<any>) {
        state.rilevazioni = surveys;
    },
    closeSurvey(state: any) {
        state.rilevazione.stato = 'T';
    },
    editProduct(state: any, editP: { id: any, prodotto: any }) {
        const id = state.rilevazioni[editP.id].prodotti.findIndex((prodotto: { ean: any; }) => prodotto.ean == editP.prodotto.ean);
        state.rilevazioni[editP.id].prodotti[id] = editP.prodotto;
    },
    newSurvey(state: any, rilevazione: any) {
        state.rilevazioni.push(rilevazione);
    },
    setNegozi(state: any, negozi: any) {
        state.negozi = negozi;
    },
    setPermissions(state: any, value: boolean) {
        state.permissionsAccepted = value;
    },
    setProdotti(state: any, prodotti: any) {
        state.prodotti = prodotti;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

