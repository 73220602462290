const state = () => ({
    token: {
        refresh_token: "",
        access_token: "",
        id_token: "",
    },
    isLogged: false,
    codiceUtente: null
})

// getters
const getters = {
    token: (state: { token: string }) => {
        return state.token;
    },
    isLogged: (state: { isLogged: boolean }) => {
        return state.isLogged;
    },
    getCodiceUtente: (state: any) => {
        return state.codiceUtente;
    }
}

// actions
const actions = {
    async setToken(state: any, token: any) {//al login salva il token
        state.token = token;
    }
}

const mutations = {
    setToken(state: any, token: any) {
        state.token = token;
    },
    setIsLogged(state: any, value: any) {
        state.isLogged = value
    },
    setCodiceUtente(state: any, value: any) {
        state.codiceUtente = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}