import apiFactory from "@/factories/apiFactory";
import qs from 'qs';
import store from '@/store';
import moment from "moment";


const URL = process.env.VUE_APP_BASE_URL;
const CODICE_SISTEMA = process.env.VUE_APP_SYSTEM_CODE;
const CODICE_SOCIETA = process.env.VUE_APP_CODICE_SOCIETA;
const DATE_FROM = process.env.VUE_APP_DATE_FROM;

const requestConfiguration = {
    headers: {
        "content-type": "application/json",
        'client-key': process.env.VUE_APP_CLIENT_KEY,
    },
    params: {},
    paramsSerializer: (params: any) => qs.stringify(params, {encode: false}),
}
const surveys = {
    async detail(id: any) {
        const result = await new Promise((resolve, reject) =>
            apiFactory.get(`${URL}/vendita/v2/stato-prezzi-concorrenza/${id}`, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
    async list(codicePuntoVenditaConcorrenza: string | null) {
        const codiceUtente = store.getters["auth/getCodiceUtente"];
        const date = moment().subtract(DATE_FROM, "days")
        const dateFrom = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        let query = `${URL}/vendita/v2/stato-prezzi-concorrenza/?_q={"dataRilevazione":{"$gt":"${dateFrom}"},"codiceUtente":{"$in":["${codiceUtente}","${codiceUtente.toLowerCase()}"]}}&_s=-updatedAt&codiceSistema=${CODICE_SISTEMA}&_l=${process.env.VUE_APP_MAX_COUNT}`
        if (codicePuntoVenditaConcorrenza != null) {
            query = `${query}&codicePuntoVenditaConcorrenza=${codicePuntoVenditaConcorrenza}`
        }
        const result = await new Promise((resolve, reject) =>
            apiFactory.get(query, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
    async countProducts(id_rilevazione: string, codicePuntoVenditaConcorrenza: string) {

        const codiceUtente = store.getters["auth/getCodiceUtente"];
        const query = `${URL}/vendita/prezzo-vendita-concorrenza/count?idRilevazione=${id_rilevazione}&_q={"codiceUtente":{"$in":["${codiceUtente}","${codiceUtente.toLowerCase()}"]}}&codicePuntoVenditaConcorrenza=${codicePuntoVenditaConcorrenza}`
        const result = await new Promise((resolve, reject) =>
            apiFactory.get(query, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
    async productsList(id_rilevazione: string, codicePuntoVenditaConcorrenza: string) {

        const codiceUtente = store.getters["auth/getCodiceUtente"];
        let query = `${URL}/vendita/prezzo-vendita-concorrenza/?idRilevazione=${id_rilevazione}&_q={"codiceUtente":{"$in":["${codiceUtente}","${codiceUtente.toLowerCase()}"]}}&_l=${process.env.VUE_APP_MAX_COUNT}&_s=-updatedAt`
        //  let query = `${URL}/vendita/prezzo-vendita-concorrenza/?idRilevazione=${id_rilevazione}`
        if (codicePuntoVenditaConcorrenza != null) {
            query = `${query}&codicePuntoVenditaConcorrenza=${codicePuntoVenditaConcorrenza}`
        }

        const result = await new Promise((resolve, reject) =>
            apiFactory.get(query, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        store.commit('surveys/setProdotti', result)
        return result
    },
    async searchDescription(ean: string): Promise<any> {
        const query = `${URL}/vendita/prezzo-vendita-concorrenza/?codiceEan=${ean}&_l=1&_s=-updatedAt&_p=descrizioneArticoloConcorrenza`

        const result = await new Promise((resolve, reject) =>
            apiFactory.get(query, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
    async searchProduct(codicePuntoVenditaConcorrenza: string | string[] | null, ean: string, idRilevazione: string | null): Promise<any> {
        let query = `${URL}/vendita/prezzo-vendita-concorrenza/?codiceEan=${ean}&_l=1&_s=-updatedAt`
        if (codicePuntoVenditaConcorrenza != null) {
            query = `${query}&codicePuntoVenditaConcorrenza=${codicePuntoVenditaConcorrenza}`;
        }
        if (idRilevazione != null) {
            query = `${query}&idRilevazione=${idRilevazione}`;
        }
        const result = await new Promise((resolve, reject) =>
            apiFactory.get(query, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
    async newRilevazione(rilevazione: any) {
        const query = `${URL}/vendita/v2/stato-prezzi-concorrenza/`
        const result = await new Promise((resolve, reject) =>
            apiFactory.post(query, rilevazione, requestConfiguration).then(
                (success) => resolve(success.data._id),
                (error) => reject(error))
        )
        return result
    },
    async addProduct(rilevazione: any, prodotto: any, codiceAmbiente: any) {
        const codiceUtente = store.getters["auth/getCodiceUtente"];
        const rilevazioneConNuovoPrezzo = {
            "idRilevazione": rilevazione.idRilevazione, // id della rilevazione creata
            "codiceAmbiente": codiceAmbiente,
            "codiceSocieta": CODICE_SOCIETA,
            "dataRilevazione": rilevazione.dataRilevazione,
            "codicePuntoVenditaConcorrenza": rilevazione.codicePuntoVenditaConcorrenza,
            "codiceEan": prodotto.codiceEan,
            "descrizioneArticoloConcorrenza": prodotto.descrizioneArticoloConcorrenza,
            "codiceSistema": CODICE_SISTEMA,
            "prezzoVendita": prodotto.prezzoVendita,
            "offerta": prodotto.offerta,
            "codiceUtente": codiceUtente,
            "note": prodotto.note,
            "sequenza": 1
        }
        const query = `${URL}/vendita/rilevazione-prezzi-concorrenza-api/v1/push-rilevazione`
        const result = await new Promise((resolve, reject) =>
            apiFactory.post(query, rilevazioneConNuovoPrezzo, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )

        return result
    },
    async closeRilevazione(id_rilevazione: any) {
        const query = `${URL}/vendita/v2/stato-prezzi-concorrenza/${id_rilevazione}`
        const result = await new Promise((resolve, reject) =>
            apiFactory.patch(query, {"$set": {"stato": "T"}}, requestConfiguration).then(
                (success) => resolve(success.data),
                (error) => reject(error))
        )
        return result
    },
}

export default surveys