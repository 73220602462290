import ApiFactory from '../factories/apiFactory';
import router from '@/router';
import store from '@/store';

interface token {
    refresh_token: string,
    access_token: string,
    id_token: string,
}

const TOKEN_URL = `${process.env.VUE_APP_BASE_URL}/oauth/token`;
const REFRESH_TOKEN_URL = `${process.env.VUE_APP_BASE_URL}/oauth/azure-ad/token`;
const LOGOUT_URL = `${process.env.VUE_APP_BASE_URL}/v1/logout`;
const LOGIN_OAUTH =  `${process.env.VUE_APP_BASE_URL}/oauth/azure-ad/authorize?redirect_uri=${process.env.VUE_APP_URL}&token_request_mode=callback&token_response_mode=query`;

const requestConfiguration = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'client-key': process.env.VUE_APP_CLIENT_KEY,
        
    }
};
const refreshConfiguration = {
    headers: {
        'client-key': process.env.VUE_APP_CLIENT_KEY,
    },
}

const authorization = {
    login(credentials: any) {
        return new Promise((resolve, reject) =>
            ApiFactory.post(TOKEN_URL, credentials, requestConfiguration)
                .then(
                    (success) => {
                        resolve(success.data);
                        store.commit('auth/setToken', success.data);
                        store.commit('auth/setIsLogged', true);
                        router.push("/")
                    },
                    (error) => reject(error))
        );
    },
    loginOauth() {
        window.open(LOGIN_OAUTH, "_self", "");
    },
    logout() {
        return new Promise((resolve, reject) =>
            ApiFactory.post(LOGOUT_URL, {}, requestConfiguration)
                .then(
                    (success) => { resolve(success.data); store.commit('auth/setIsLogged', false) },
                    (error) => reject(error))
        );
    },
    refreshtoken(credentials: any): Promise<token> {
        return new Promise((resolve, reject) =>
            ApiFactory.post(REFRESH_TOKEN_URL, {
                "refresh_token": credentials,
                grant_type: "refresh_token"
            }, refreshConfiguration)
                .then(
                    (success) => {
                        resolve(success.data);
                        store.commit('auth/setToken', success.data);
                        router.push("/")
                    },
                    (error) => reject(error))
        );
    }
};

export default authorization;
