import Axios from 'axios';
import store from '@/store';
import authorization from '@/services/authorization';


const apiFactory = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
});



apiFactory.interceptors.request.use((config: any) => {
  const token = store.getters['auth/token'];
  if (token.id_token != "") {
    config.headers.Authorization = `Bearer ${token.id_token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

apiFactory.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      const result = await authorization.refreshtoken(store.getters['auth/token'].refresh_token);

      if (result?.id_token) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.id_token}`,
        };
      }
      return apiFactory(config);
    }
    return Promise.reject(error);
  }
);


export default apiFactory;
