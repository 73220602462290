
import { useOnline } from "@vueuse/core";
import {
  IonBackdrop,
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane, useBackButton,
} from "@ionic/vue";
import {defineComponent, Ref, ref} from "vue";
import { barcodeOutline, homeOutline, listOutline } from "ionicons/icons";
import store from "./store";

export default defineComponent({
  name: "App",
  components: {
    IonBackdrop,
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonMenuButton,
  },
  computed: {
    isLogged() {
      return store.getters["auth/isLogged"];
    },
  },
  setup() {
    useBackButton(10, () => {
      console.log('Handler was called!');
    });
    const online = useOnline();
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Nuova Rilevazione",
        url: "/shops",
        iosIcon: barcodeOutline,
        mdIcon: barcodeOutline,
      },
      {
        title: "Lista Rilevazioni",
        url: "/surveys",
        iosIcon: listOutline,
        mdIcon: listOutline,
      },
    ];
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
    const refreshing = false;
    const registration: Ref<ServiceWorkerRegistration> | null = ref(null);
    return {
      online,
      homeOutline,
      selectedIndex,
      appPages,
      listOutline,
      barcodeOutline,
      refreshing,
      registration
    };
  },
  created() {
    console.log('updateAvailable');
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    if (navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    }
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    async updateAvailable(event: any) {
      console.log('updateAvailable');
      this.registration = event.detail;
      this.refreshing = false;
      this.refreshApp();
    },
    // Called when the user accepts the update
    refreshApp() {
      console.log('refreshApp');
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});
